import {
  reportBenefitCallback,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  LBTButton,
  LBTLabel,
  LBTSpacer,
  LBTTextField,
  Section,
} from '@laborability/components';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import ThankYouPage from '../../components/ThankYouPage';

export default function MeasureReport() {
  const sendReport = useRecoilCallback(reportBenefitCallback, []);
  const [isReportCompleted, setIsReportCompleted] = useState(false);
  const [values, setValues] = useState<{ name: string; url: string }>({
    name: '',
    url: '',
  });
  const [errors, setErrors] = useState<{ name: boolean; url: boolean }>({
    name: false,
    url: false,
  });

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Segnala una agevolazione' },
    { key: 'page_location', value: '/menu/segnala-agevolazione/' },
  ]);

  return (
    <Section>
      {isReportCompleted ? (
        <ThankYouPage
          title="Grazie per la segnalazione"
          subtitle="Il tuo contributo è prezioso e utile a tutte le persone, perché ci permette di arricchire la mappa delle agevolazioni. Quindi davvero grazie grazie grazie!"
        />
      ) : (
        <>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="delaDisplay" component="h1">
            Segnala un'agevolazione
          </LBTLabel>
          <LBTSpacer spacing={2} />
          <LBTLabel variant="bodyText">
            Compila i campi per aiutarci nell’impresa di mappare tutte le
            opportunità che esistono sul territorio italiano
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTTextField
            label="Nome del bonus"
            type="text"
            name="name"
            onChange={value => {
              setErrors({ ...errors, name: false });
              setValues({ ...values, name: value ?? '' });
            }}
            value={values.name}
            required
            hasAsterisk={false}
            error={errors.name}
            helperText={
              errors.name
                ? 'Questo campo è obbligatorio. Compilalo per inviare la tua segnalazione'
                : 'Scrivi il nome ufficiale o, se non lo conosci, specifica l’ambito del bonus'
            }
            sx={{ maxWidth: '680px' }}
          />
          <LBTSpacer spacing={4} />
          <LBTTextField
            label="Pagina o url"
            type="text"
            name="url"
            onChange={value => {
              setErrors({ ...errors, url: false });
              setValues({ ...values, url: value ?? '' });
            }}
            value={values.url}
            required
            hasAsterisk={false}
            error={errors.url}
            helperText={
              errors.url
                ? 'Questo campo è obbligatorio. Compilalo per inviare la tua segnalazione'
                : 'Inserisci una fonte ufficiale (es. Ministero, Inps o altro ente). Se hai un link da incollare ancora meglio!'
            }
            sx={{ maxWidth: '680px' }}
          />
          <LBTSpacer spacing={4} />
          <LBTButton
            variant="contained"
            disabled={errors.name || errors.url}
            onClick={async () => {
              if (!values.name || !values.url) {
                setErrors({ name: !values.name, url: !values.url });
              } else {
                const res = await sendReport({
                  page_url: values.url,
                  benefit_name: values.name,
                });
                if (res?.data) setIsReportCompleted(true);
              }
            }}
            fullWidth
            maxWidth="680px"
          >
            Avanti
          </LBTButton>
          <LBTSpacer spacing={4} />
        </>
      )}
    </Section>
  );
}
