import {
  checkQuestionaryAccess,
  currentCompanyConfigState,
  useBreakpoint,
} from '@laborability/commons';
import {
  COLORS,
  IconsSize,
  IconsStyle,
  IconSearchComponent,
  LBTButtonIcon,
  IconBurger,
  IconCross,
  LBTImage,
  LBTDialog,
  IconHomeComponent,
  QuestionaryContext,
} from '@laborability/components';
import { Button, Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { APP_ROUTES } from '../../Routes';
import { useRecoilValue } from 'recoil';
import { useContext, useState } from 'react';

const StyledLogoButton = styled(Button)<{
  datatestid: string;
}>(({ datatestid }) => {
  return {
    datatestid,
    marginLeft: '16px',
  };
});

const StyledStickyHeader = styled(Stack)<{
  backgroundColor: string;
  height: string;
}>`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid ${COLORS.getInstance().BLACK};
  ${props => `height: ${props.height};`}
  transition: all 0.5s linear;
  left: 0;
`;
export type LBTNavMenuProps = {
  backgroundColor?: string;
};

const NavMenu: React.FC<LBTNavMenuProps> = ({
  backgroundColor = COLORS.getInstance().WHITE,
}: LBTNavMenuProps) => {
  const { logo, realm } = useRecoilValue(currentCompanyConfigState);
  const { step, setStep, setIsStepCompleted } = useContext(QuestionaryContext);
  const hasQuestionaryAccess = checkQuestionaryAccess(realm ?? '');
  const location = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useBreakpoint();
  const [isModalOpen, setIsModalOpen] = useState<string>('');

  const confirmNavigate = (navigateTo: string) => {
    if (location.pathname === `/${APP_ROUTES.QUESTIONARY}` && step)
      setIsModalOpen(navigateTo);
    else navigate(navigateTo);
  };

  return (
    <>
      <StyledStickyHeader
        height={isDesktop ? '100px' : '64px'}
        backgroundColor={backgroundColor}
        direction="column"
      >
        <Stack
          direction="row"
          alignItems="center"
          width="100%"
          height="100%"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <StyledLogoButton
              datatestid="link_topbar_logo"
              onClick={() => {
                setIsStepCompleted(false);
                confirmNavigate(APP_ROUTES.HOME);
              }}
            >
              {logo ? (
                <img
                  src={logo}
                  style={{
                    height: isDesktop ? '24px' : '20px',
                  }}
                  alt=""
                />
              ) : (
                <LBTImage
                  image="laborabilityLogo"
                  height={isDesktop ? '24px' : '20px'}
                  width={isDesktop ? '182px' : '122px'}
                />
              )}
            </StyledLogoButton>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            marginRight={'16px'}
          >
            <LBTButtonIcon
              datatestid="link_topbar_search"
              onClick={() => {
                setIsStepCompleted(false);
                confirmNavigate(`${APP_ROUTES.SEARCH_MISURE}`);
              }}
            >
              <IconSearchComponent
                datatestid="link_topbar_search"
                size={IconsSize.LARGE}
                style={IconsStyle.FILLED}
                color={COLORS.getInstance().BLACK}
              />
            </LBTButtonIcon>
            {hasQuestionaryAccess ? (
              <LBTButtonIcon
                datatestid="link_topbar_burger"
                onClick={() => {
                  setIsStepCompleted(false);
                  if (location.pathname === `/${APP_ROUTES.MENU}`)
                    return confirmNavigate(APP_ROUTES.HOME);
                  confirmNavigate(APP_ROUTES.MENU);
                }}
              >
                {location.pathname === `/${APP_ROUTES.MENU}` ? (
                  <IconCross datatestid="link_topbar_burger" />
                ) : (
                  <IconBurger datatestid="link_topbar_burger" />
                )}
              </LBTButtonIcon>
            ) : (
              <LBTButtonIcon
                datatestid="link_topbar_home"
                onClick={() => {
                  return confirmNavigate(APP_ROUTES.HOME);
                }}
              >
                <IconHomeComponent
                  size={IconsSize.LARGE}
                  style={IconsStyle.FILLED}
                  color={COLORS.getInstance().BLACK}
                />
              </LBTButtonIcon>
            )}
          </Stack>
        </Stack>
      </StyledStickyHeader>
      <LBTDialog
        open={!!isModalOpen}
        onClose={() => setIsModalOpen('')}
        onCancel={() => setIsModalOpen('')}
        onSubmit={() => {
          setStep(0);
          navigate(isModalOpen);
          setIsModalOpen('');
        }}
        title="Confermi di voler uscire dal questionario?"
      >
        Le risposte che ci hai dato fin qui sono salvate e puoi vederle dal
        menu. Puoi modificarle da lì in qualsiasi momento.
      </LBTDialog>
    </>
  );
};

export default NavMenu;
