export const IconGenitorialita = ({ locked = false }: { locked?: boolean }) => {
  return locked ? (
    <svg
      width="47"
      height="46"
      viewBox="0 0 47 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Stroller--Streamline-Flex.svg" clip-path="url(#clip0_10373_16566)">
        <g id="Stroller--Streamline-Flex.svg_2">
          <path
            id="Union"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.3991 0C27.9626 0 27.544 0.175037 27.2354 0.486604C26.9268 0.798171 26.7534 1.22075 26.7534 1.66137V14.9523C26.7534 15.393 26.9268 15.8155 27.2354 16.1271C27.544 16.4387 27.9626 16.6137 28.3991 16.6137C28.8355 16.6137 29.2541 16.4387 29.5627 16.1271C29.8713 15.8155 30.0447 15.393 30.0447 14.9523H43.2099C43.2099 15.393 43.3832 15.8155 43.6919 16.1271C44.0005 16.4387 44.419 16.6137 44.8555 16.6137C45.292 16.6137 45.7105 16.4387 46.0191 16.1271C46.3278 15.8155 46.5011 15.393 46.5011 14.9523C46.5011 12.9888 46.1181 11.0444 45.3737 9.23032C44.6294 7.41622 43.5385 5.76789 42.1632 4.37944C40.7879 2.99098 39.1551 1.8896 37.3582 1.13818C35.5613 0.386753 33.6353 0 31.6904 0L28.3991 0Z"
            fill="#D4D4D4"
          />
          <path
            id="Union_2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.71041 7.06402C8.63264 6.62868 8.43826 6.22315 8.14837 5.89142C7.85849 5.5597 7.48415 5.31446 7.06598 5.1823C6.64781 5.05013 6.20178 5.0361 5.77627 5.14172C5.35077 5.24735 4.96204 5.46859 4.65225 5.78145C4.18431 6.22164 3.5654 6.46129 2.9259 6.4499C2.2864 6.43851 1.67624 6.17697 1.22397 5.72038C0.771708 5.26379 0.512644 4.64781 0.501361 4.00219C0.490078 3.35658 0.727456 2.73175 1.16349 2.25934C5.35 -1.97383 12.5184 0.298925 13.5683 6.18017L15.1317 14.9522H46.501V18.2749C46.501 23.6711 43.6441 27.1134 39.9513 29.0938C36.3605 31.021 31.9502 31.6157 28.3989 31.5659C24.8509 31.6157 20.4406 31.0243 16.8465 29.0938C13.1602 27.1134 10.2968 23.6711 10.2968 18.2749V16.6136C10.2969 16.4826 10.3124 16.3521 10.3429 16.2248L8.71041 7.06402Z"
            fill="#9B9B9B"
          />
          <path
            id="Vector"
            d="M18.5252 45.9999C22.4089 45.9999 24.5943 43.7936 24.5943 39.8727C24.5943 35.9519 22.4089 33.7456 18.5252 33.7456C14.6415 33.7456 12.4561 35.9519 12.4561 39.8727C12.4561 43.7936 14.6415 45.9999 18.5252 45.9999Z"
            fill="#D4D4D4"
          />
          <path
            id="Vector_2"
            d="M38.2727 45.9999C42.1565 45.9999 44.3419 43.7936 44.3419 39.8727C44.3419 35.9519 42.1565 33.7456 38.2727 33.7456C34.389 33.7456 32.2036 35.9519 32.2036 39.8727C32.2036 43.7936 34.389 45.9999 38.2727 45.9999Z"
            fill="#D4D4D4"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10373_16566">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(0.500977)"
          />
        </clipPath>
      </defs>
    </svg>
  ) : (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3241_1001)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.8976 1C28.4611 1 28.0426 1.17504 27.734 1.4866C27.4253 1.79817 27.252 2.22075 27.252 2.66137V15.9523C27.252 16.393 27.4253 16.8155 27.734 17.1271C28.0426 17.4387 28.4611 17.6137 28.8976 17.6137C29.334 17.6137 29.7526 17.4387 30.0612 17.1271C30.3699 16.8155 30.5432 16.393 30.5432 15.9523H43.7084C43.7084 16.393 43.8818 16.8155 44.1904 17.1271C44.499 17.4387 44.9176 17.6137 45.354 17.6137C45.7905 17.6137 46.2091 17.4387 46.5177 17.1271C46.8263 16.8155 46.9997 16.393 46.9997 15.9523C46.9997 13.9888 46.6166 12.0444 45.8723 10.2303C45.128 8.41622 44.037 6.76789 42.6617 5.37944C41.2864 3.99098 39.6537 2.8896 37.8567 2.13818C36.0598 1.38675 34.1339 1 32.1889 1L28.8976 1Z"
          fill="#FFA084"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.20943 8.06402C9.13166 7.62868 8.93729 7.22315 8.6474 6.89142C8.35751 6.5597 7.98317 6.31446 7.56501 6.1823C7.14684 6.05013 6.70081 6.0361 6.2753 6.14172C5.84979 6.24735 5.46106 6.46859 5.15127 6.78145C4.68334 7.22164 4.06442 7.46129 3.42492 7.4499C2.78542 7.43851 2.17526 7.17697 1.723 6.72038C1.27073 6.26379 1.01167 5.64781 1.00038 5.00219C0.989101 4.35658 1.22648 3.73175 1.66251 3.25934C5.84903 -0.973829 13.0175 1.29892 14.0674 7.18017L15.6307 15.9522H47V19.2749C47 24.6711 44.1432 28.1134 40.4503 30.0938C36.8595 32.021 32.4492 32.6157 28.8979 32.5659C25.3499 32.6157 20.9396 32.0243 17.3455 30.0938C13.6593 28.1134 10.7958 24.6711 10.7958 19.2749V17.6136C10.796 17.4826 10.8114 17.3521 10.8419 17.2248L9.20943 8.06402Z"
          fill="#386CCC"
        />
        <path
          d="M19.0242 46.9999C22.9079 46.9999 25.0933 44.7936 25.0933 40.8727C25.0933 36.9519 22.9079 34.7456 19.0242 34.7456C15.1405 34.7456 12.9551 36.9519 12.9551 40.8727C12.9551 44.7936 15.1405 46.9999 19.0242 46.9999Z"
          fill="#80ABF9"
        />
        <path
          d="M38.7713 46.9999C42.655 46.9999 44.8404 44.7936 44.8404 40.8727C44.8404 36.9519 42.655 34.7456 38.7713 34.7456C34.8876 34.7456 32.7021 36.9519 32.7021 40.8727C32.7021 44.7936 34.8876 46.9999 38.7713 46.9999Z"
          fill="#80ABF9"
        />
      </g>
      <defs>
        <clipPath id="clip0_3241_1001">
          <rect
            width="46"
            height="46"
            fill="white"
            transform="translate(1 1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
