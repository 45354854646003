import {
  getUserEligibleMisureCallback,
  measuresState,
  Misura,
  useTrackAnalytics,
} from '@laborability/commons';
import {
  CardVariant,
  COLORS,
  LBTButton,
  LBTCard,
  LBTDivider,
  LBTDividerSize,
  LBTImage,
  LBTLabel,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { Grid, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { APP_ROUTES } from '../../Routes';
import InfiniteDiscover from '../../components/InfiniteDiscover';

export default function MyBonus() {
  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Menu - Il mio questionario' },
    { key: 'page_location', value: '/menu/tue-agevolazioni/' },
  ]);

  const fetchMisure = useRecoilCallback(getUserEligibleMisureCallback, []);
  const measures = useRecoilValue(measuresState);
  const navigate = useNavigate();

  const getMisure = async (skip: number, limit: number) => {
    const res = await fetchMisure({
      skip,
      limit,
    });
    if (!res || !res.data) {
      return [];
    }
    return res.data;
  };

  return (
    <Section>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Le tue agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Bonus, incentivi, sgravi fiscali e opportunità che ti spettano in base
        alle risposte del Questionario
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTImage image={measures.length ? 'hasBonus' : 'hasNoBonus'} />
      <LBTSpacer spacing={2} />
      <LBTDivider
        orientation="horizontal"
        width="100%"
        size={LBTDividerSize.SMALL}
        color={COLORS.getInstance().DIVIDER}
      />
      <LBTSpacer spacing={2} />
      <InfiniteDiscover
        items={measures}
        lastSpacer={4}
        getMoreItems={() => getMisure(0, 200)}
        hasTotalItemsLabel
        renderItem={(item: Misura) => (
          <Grid item mobile={12} desktop={6} display="flex">
            <LBTCard
              datatestid={`cat_bonus_id_${item.id}`}
              onClick={() =>
                navigate(`/${APP_ROUTES.MEASURE_DETAIL}/${item.id}`)
              }
              title={item.name}
              description={item.description}
              tag={item.categories
                ?.find(category =>
                  item.categories!.length === 2
                    ? !!category.parent_id
                    : category.parent_id === null,
                )
                ?.name?.toLowerCase()}
              news={item.news ?? false}
              expired={item.expired ?? false}
              expiringSoon={item.expiring_soon ?? false}
              upcoming={item.upcoming ?? false}
              cardVariant={CardVariant.CONTAINED}
            />
          </Grid>
        )}
      />
      {!measures.length && (
        <Stack
          direction={'column'}
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <LBTLabel variant={'bodyText2'}>
            Non hai finito il Questionario o vuoi modificare le risposte?
          </LBTLabel>
          <LBTSpacer spacing={4} />
          <LBTButton
            size="large"
            variant="contained"
            color="primary"
            onClick={() => navigate(`/${APP_ROUTES.QUESTIONARY_MENU}`)}
            fullWidth
            maxWidth="504px"
          >
            Vai al Questionario
          </LBTButton>
        </Stack>
      )}
    </Section>
  );
}
