import {
  COLORS,
  IconArrowDownComponent,
  IconsSize,
  IconsStyle,
  LBTButton,
  LBTLabel,
  LBTPagination,
  LBTSpacer,
} from '@laborability/components';
import { Grid, Stack } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useBreakpoint } from '@laborability/commons';

interface Props<T> {
  items: T[];
  getMoreItems: (
    skip: number,
    limit: number,
    progressive: boolean,
    params?: {},
  ) => Promise<{ items: T[]; total: number }>;
  renderItem: (item: T) => ReactNode;
  take?: number;
  params?: { [x: string]: any };
  scrollMode?: 'vertical' | 'horizontal';
  hasTotalItemsLabel?: boolean;
  customLabelDescription?: string;
  lastSpacer?: number;
}

export default function InfiniteDiscover<T>({
  items,
  getMoreItems,
  renderItem,
  take = 10,
  params,
  scrollMode = 'vertical',
  hasTotalItemsLabel = false,
  customLabelDescription = '',
  lastSpacer = 6,
}: Props<T>) {
  const { isDesktop } = useBreakpoint();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const hasMoreElements = items.length < totalItems;

  const getItems = async () => {
    await getMoreItems(items.length, take, true, params);
  };

  const resetItems = async () => {
    const data = await getMoreItems(0, take, false, params);
    if (!data) return;
    setTotalItems(data?.total ?? 0);
  };

  useEffect(() => {
    resetItems();
  }, [...(params ? Object.values(params) : [])]);

  return (
    <Stack
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      {hasTotalItemsLabel && (
        <LBTLabel
          variant="sourceCaption"
          component="small"
          maxWidth={isDesktop ? '1032px' : undefined}
        >
          {`${totalItems} risultati${customLabelDescription}`}
        </LBTLabel>
      )}
      <LBTSpacer spacing={2} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        sx={{
          maxWidth: isDesktop ? '1032px' : undefined,
        }}
      >
        {scrollMode === 'horizontal'
          ? items.length
            ? renderItem(items[currentPage - 1])
            : null
          : items.map((item: T) => renderItem(item))}
      </Grid>
      {scrollMode === 'horizontal' ? (
        <LBTPagination
          numberOfItems={items.length}
          onPageChange={tmpPage => setCurrentPage(tmpPage)}
          currentPage={currentPage}
        />
      ) : (
        hasMoreElements && (
          <>
            <LBTSpacer spacing={2} />
            <LBTButton
              variant="invisible"
              onClick={() => {
                getItems();
              }}
              endIcon={
                <IconArrowDownComponent
                  size={IconsSize.SMALL}
                  style={IconsStyle.FILLED}
                  color={COLORS.getInstance().PRIMARY_DARK}
                />
              }
            >
              Mostra più risultati
            </LBTButton>
          </>
        )
      )}
      <LBTSpacer spacing={lastSpacer} />
    </Stack>
  );
}
