import {
  COLORS,
  LBTButton,
  LBTButtonIllustrative,
  LBTImage,
  LBTLabel,
  LBTPagination,
  LBTSpacer,
  Section,
} from '@laborability/components';
import { useEffect, useState } from 'react';
import {
  categoriesState,
  getAllMisureCallback,
  getCategoriesHomePageCallback,
  measuresState,
  slugify,
  useBreakpoint,
  useTrackAnalytics,
} from '@laborability/commons';
import { Grid } from '@mui/material';
import { useRecoilValue, useRecoilCallback } from 'recoil';

const ITEMS = [
  {
    image: <LBTImage image="manWithMagnifyingGlass" />,
    title: '1. Profilo personale',
    subtitle: 'domande semplici per iniziare e rompere il ghiaccio',
    color: COLORS.getInstance().TANGERINE_MAIN,
  },
  {
    image: <LBTImage image="family" />,
    title: '2. I tuoi cari',
    subtitle:
      'perché ci teniamo a mostrarti anche i bonus per chi ti sta a cuore',
    color: COLORS.getInstance().BERRY_POPSICLE_MAIN,
  },
  {
    image: <LBTImage image="keyDoor" />,
    title: '3. Super domande',
    subtitle: 'domande difficili ma cruciali per valutare tantissimi bonus',
    color: COLORS.getInstance().MINT_TONIC_MAIN,
  },
  {
    image: <LBTImage image="puzzle" />,
    title: '4. Le categorie',
    subtitle:
      'domande specifiche per sbloccare i bonus che ti interessano, divisi per area tematica',
    color: COLORS.getInstance().LEMON_CAKE_MAIN,
  },
];

interface Props {
  closeIntro: () => void;
}

export default function Intro({ closeIntro }: Props) {
  const [currentPage, setCurrentPage] = useState(0);

  useTrackAnalytics(
    currentPage < ITEMS.length + 1
      ? [
          { key: 'event', value: 'page_view' },
          {
            key: 'page_title',
            value: `Questionario - Intro step ${currentPage + 1}`,
          },
          {
            key: 'page_location',
            value: `/onboarding/intro/${currentPage + 1}`,
          },
        ]
      : [
          { key: 'event', value: 'page_view' },
          {
            key: 'page_title',
            value: 'Questionario - Overview misure sbloccate',
          },
          { key: 'page_location', value: '/onboarding/overview' },
        ],
  );

  const renderItem = (item: (typeof ITEMS)[number]) => {
    return (
      <Grid item key={item.title} mobile={12} desktop={6} display="flex">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            border: `1px solid ${item.color}`,
            borderRadius: '12px',
            backgroundColor: `${item.color}26`,
            alignItems: 'center',
            width: '100%',
          }}
        >
          {item.image}
          <LBTLabel variant="bodyText" style={{ fontWeight: 700 }}>
            {item.title}
          </LBTLabel>
          <LBTLabel variant="bodyText">{item.subtitle}</LBTLabel>
        </div>
      </Grid>
    );
  };

  if (currentPage === 0)
    return (
      <Section>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="delaDisplay" component="h1">
          Semplifichiamo la complessità
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTLabel variant="spGroteskSubtitle" component="h2">
          Là fuori c'è una giungla di bonus, incentivi, agevolazioni, sgravi
          fiscali. Capirci qualcosa è un'impresa, ma la fatica la facciamo noi
          per te
        </LBTLabel>
        <LBTSpacer spacing={2} />
        <LBTImage image="turtleAndBunny" />
        <LBTSpacer spacing={2} />
        <LBTLabel variant="bodyText">
          Rispondi alle domande del Questionario e sblocca i tuoi bonus: solo
          quelli che ti spettano, senza rumore di fondo
        </LBTLabel>
        <LBTSpacer spacing={4} />
        <LBTButton
          onClick={() => {
            setCurrentPage(1);
          }}
          variant="contained"
          size="large"
          fullWidth
          maxWidth="358px"
          datatestid="onboarding_button_page_1_next"
        >
          Avanti
        </LBTButton>
      </Section>
    );

  if (currentPage === ITEMS.length + 1)
    return <Onboarding closeIntro={closeIntro} />;

  return (
    <Section>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Sblocca le tue agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Abbiamo preparato una serie di domande. Le tue risposte ci permettono di
        valutare centinaia di bonus e a selezionare solo quelli giusti per te
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <div style={{ maxWidth: '504px', width: '100%' }}>
        {ITEMS && renderItem(ITEMS[currentPage - 1])}
      </div>
      <LBTSpacer spacing={2} />
      <LBTPagination
        numberOfItems={ITEMS.length}
        onPageChange={tmpPage => setCurrentPage(tmpPage)}
        currentPage={currentPage}
        leftBtnDatatestid="onboarding_carousel_left"
        rightBtnDatestid="onboarding_carousel_right"
      />

      <LBTSpacer spacing={4} />
      {currentPage === 4 ? (
        <LBTButton
          onClick={() => setCurrentPage(ITEMS.length + 1)}
          variant="contained"
          size="large"
          fullWidth
          maxWidth="358px"
          datatestid="onboarding_button_pre-questionario_next"
        >
          Avanti
        </LBTButton>
      ) : (
        <LBTButton
          onClick={() => setCurrentPage(ITEMS.length + 1)}
          variant="invisible"
          size="large"
          fullWidth
          maxWidth="358px"
          datatestid="onboarding_button_description_skip"
        >
          Salta l'introduzione
        </LBTButton>
      )}
      <LBTSpacer spacing={6} />
    </Section>
  );
}

function Onboarding({ closeIntro }: Props) {
  const { isDesktop } = useBreakpoint();

  const categories = useRecoilValue(categoriesState);
  const misure = useRecoilValue(measuresState);
  const getCategories = useRecoilCallback(getCategoriesHomePageCallback);
  const getMisure = useRecoilCallback(getAllMisureCallback);

  useEffect(() => {
    getCategories();
    // getMisure({ limit: 1000 });
  }, []);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Le tue agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        Sblocca le agevolazioni che ti spettano rispondendo alle domande del
        questionario
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="bodyText" component="p">
        Alla fine tornerai qui e vedrai incentivi, sgravi e bonus divisi per
        categoria. Per sbloccarli tutti, entra nelle categorie che ti
        interessano e rispondi a qualche altra domanda
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTButton
        onClick={closeIntro}
        size="large"
        sx={{ width: '100%', maxWidth: '358px' }}
        variant="contained"
        color="primary"
        datatestid="onboarding_button_questionario_start"
      >
        Inizia il Questionario
      </LBTButton>
      <LBTSpacer spacing={4} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        style={{ maxWidth: isDesktop ? '1032px' : '390px', width: '100%' }}
      >
        {categories.map(category => (
          <Grid key={category.id} item mobile={6} desktop={4}>
            <LBTButtonIllustrative
              datatestid={`home_button_cat_${slugify(category.name!)}_locked`}
              onClick={() => {}}
              icon={category.icon}
              label={category.name!}
              // subLabel={`${misure
              //   .filter(misura => misura.categories_ids?.includes(category.id!))
              //   .length.toString()} bonus`}
              locked={true}
              iconBgColor={COLORS.getInstance().TANGERINE_MAIN}
            />
          </Grid>
        ))}
      </Grid>
      <LBTSpacer spacing={6} />
    </Section>
  );
}
