import {
  LBTLabel,
  COLORS,
  LBTSpacer,
  Section,
  LBTButtonIllustrative,
  LBTAlert,
  LBTButton,
} from '@laborability/components';
import {
  currentUserState,
  useBreakpoint,
  useTrackAnalytics,
} from '@laborability/commons';
import { Grid } from '@mui/material';
import { IconCasa } from '../../components/icons/IconCasa';
import { IconCultura } from '../../components/icons/IconCultura';
import { IconGenitorialita } from '../../components/icons/IconGenitorialita';
import { IconIstruzione } from '../../components/icons/IconIstruzione';
import { IconSalute } from '../../components/icons/IconSalute';
import { IconTrasporti } from '../../components/icons/IconTrasporti';
import { useRecoilValue } from 'recoil';

interface Props {
  setStep: (i: number) => void;
}

export default function MyConcessions({ setStep }: Props) {
  const { isDesktop } = useBreakpoint();
  const currentUser = useRecoilValue(currentUserState);

  const isStepCompleted = (step: number) => {
    return currentUser.questionary_status?.some(
      questionary => questionary.completed && questionary.step === step,
    );
  };

  const isStepThreeNotCompleted = () => {
    return currentUser.questionary_status?.some(
      questionary =>
        questionary.step === 3 && questionary.total_responses === 0,
    );
  };

  useTrackAnalytics([
    { key: 'event', value: 'page_view' },
    { key: 'page_title', value: 'Questionario - Overview misure sbloccate' },
    { key: 'page_location', value: '/onboarding/overview' },
  ]);

  return (
    <Section backgroundColor={COLORS.getInstance().WHITE}>
      <LBTSpacer spacing={4} />
      <LBTLabel variant="delaDisplay" component="h3">
        Le tue agevolazioni
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle">
        Sblocca le agevolazioni che ti spettano rispondendo a qualche domanda
        per ogni categoria. Abbiamo già fatto una grossa scrematura grazie alle
        risposte che ci hai dato fin qui
      </LBTLabel>
      <LBTSpacer spacing={4} />
      {isStepThreeNotCompleted() && (
        <LBTAlert
          sx={{
            maxWidth: '358px',
          }}
          onClose={() => {}}
          color="error"
          variant="standard"
          title="Bonus non verificabili"
          message="Sono le agevolazioni legate alle super domande. Se ne hai saltate, ti consigliamo di rispondere anche solo con una stima"
          datatestid={''}
          customCloseComponent={
            <LBTButton
              color="error"
              size="small"
              variant="invisible"
              onClick={() => setStep(3)}
              datatestid="onboarding_button_risolvi_sblocca_agevolazioni"
            >
              RISOLVI
            </LBTButton>
          }
        />
      )}
      <LBTSpacer spacing={4} />
      <Grid
        container
        spacing={isDesktop ? '24px' : '16px'}
        style={{ maxWidth: isDesktop ? '1032px' : '390px', width: '100%' }}
      >
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`onboarding_button_cat_salute_unlocked`}
            onClick={() => setStep(4)}
            icon={<IconSalute locked={isStepCompleted(4)} />}
            label="Salute"
            locked={isStepCompleted(4)}
            iconBgColor={
              isStepCompleted(4)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_genitorialita_unlocked`}
            onClick={() => setStep(5)}
            icon={<IconGenitorialita locked={isStepCompleted(5)} />}
            label="Genitorialità"
            locked={isStepCompleted(5)}
            iconBgColor={
              isStepCompleted(5)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_casa_unlocked`}
            onClick={() => setStep(6)}
            icon={<IconCasa locked={isStepCompleted(6)} />}
            label="Casa"
            locked={isStepCompleted(6)}
            iconBgColor={
              isStepCompleted(6)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_trasporti_unlocked`}
            onClick={() => setStep(7)}
            icon={<IconTrasporti locked={isStepCompleted(7)} />}
            label="Trasporti"
            locked={isStepCompleted(7)}
            iconBgColor={
              isStepCompleted(7)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_cultura_unlocked`}
            onClick={() => setStep(8)}
            icon={<IconCultura locked={isStepCompleted(8)} />}
            label="Cultura"
            locked={isStepCompleted(8)}
            iconBgColor={
              isStepCompleted(8)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
        <Grid item mobile={6} desktop={4}>
          <LBTButtonIllustrative
            datatestid={`home_button_cat_istruzione_unlocked`}
            onClick={() => setStep(9)}
            icon={<IconIstruzione locked={isStepCompleted(9)} />}
            label="Istruzione"
            locked={isStepCompleted(9)}
            iconBgColor={
              isStepCompleted(9)
                ? COLORS.getInstance().MINT_TONIC_MAIN
                : COLORS.getInstance().PRIMARY_MAIN
            }
          />
        </Grid>
      </Grid>
      <LBTSpacer spacing={4} />
    </Section>
  );
}
