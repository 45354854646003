import { COLORS, LBTLabel, LBTSpacer, Section } from '@laborability/components';
import { useBreakpoint } from '@laborability/commons';
import { Link, Stack } from '@mui/material';
import BoldSpan from '../../components/answerBoxes/BoldSpan';

export default function InformationArt13() {
  const { isDesktop } = useBreakpoint();

  return (
    <Section
      backgroundColor={COLORS.getInstance().WHITE}
      padding={0}
      margin={0}
    >
      <LBTSpacer spacing={2} />
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        INFORMAZIONI SUL TRATTAMENTO DEI DATI
      </LBTLabel>
      <LBTLabel variant="spGroteskSubtitle" component="h2">
        ai sensi degli artt. 13 e 14 del Regolamento (UE) 2016/679
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <Stack
        style={{ flexDirection: 'column' }}
        gap={isDesktop ? '32px' : '16px'}
        marginBottom="64px"
        justifyContent={'center'}
        alignItems={'center'}
      >
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          La presente informativa è resa ai sensi degli artt. 13 e 14 del
          Regolamento UE 2016/679 ("GDPR"), e ha lo scopo di informare, riguardo
          al trattamento dei loro dati personali, tutti i soggetti che accedono
          alla piattaforma web-based “Dritto” (di seguito, “Sito” o
          “Piattaforma”) per usufruire dei servizi erogati da Laborability
          S.r.l., consistenti in un servizio informativo altamente specializzato
          volto a facilitare l’accesso alle misure di welfare pubblico e welfare
          bilaterale periodicamente disponibili per i lavoratori subordinati e
          autonomi italiani, nonché di altri servizi accessori e/o collegati (di
          seguito, “SERVIZIO”). Nel presente documento, con il termine UTENTE/I
          ci si riferisce alle persone fisiche, dipendenti e/o lavoratori
          autonomi, a favore dei quali viene erogato il SERVIZIO.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          1. Titolare del trattamento e Responsabile della Protezione dei Dati
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          <BoldSpan>Laborability S.r.l.</BoldSpan> con sede in 35131 Padova
          (Italia), via Francesco Rismondo 2/E | P.IVA/c.f. 05372450287 (“
          <BoldSpan>Titolare del trattamento</BoldSpan>” o “
          <BoldSpan>LBT</BoldSpan>”) è responsabile della protezione, sicurezza
          e legittimo utilizzo dei Suoi dati. In ogni momento, potrà contattare
          LBT all’indirizzo e-mail:{' '}
          <Link
            href="mailto:privacy@laborability.com"
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          >
            privacy@laborability.com
          </Link>
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Il Responsabile della Protezione dei Dati Personali (“DPO”) è
          raggiungibile all’indirizzo:{' '}
          <Link
            href="mailto:dpo@laborability.com"
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          >
            dpo@laborability.com
          </Link>
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          2. Categorie e tipologie di Dati Personali e relative fonti
        </LBTLabel>

        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          In occasione dell’accesso e della fruizione della Piattaforma messa a
          disposizione da LBT, i dati personali (“<BoldSpan>Dati”</BoldSpan> o “
          <BoldSpan>Dati personali</BoldSpan>”), comunicati dagli UTENTI,
          oggetto di trattamento sono i seguenti:
        </LBTLabel>

        <Stack
          paddingLeft="100px"
          sx={{
            listStyle: 'decimal',
            listStylePosition: 'outside',
          }}
        >
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            I dati di contatto degli UTENTI (ad es. indirizzo e-mail).
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            I dati relativi alle sessioni di navigazione sul Sito da parte degli
            UTENTI (ad es. indirizzo IP, cookies, log access, username e
            credenziali di accesso all’Area riservata).
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            I dati identificativi degli UTENTI, nonché i dati anagrafici (es.
            età), dati relativi al nucleo familiare (es. numero figli), dati
            reddituali, dati socioeconomici (es. stato civile),
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            I dati relativi alla salute, e altre categorie particolari di dati.
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Le valutazioni, le recensioni e il gradimento resi dagli UTENTI.
          </LBTLabel>
        </Stack>

        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          I Dati personali sono raccolti dal Titolare direttamente presso
          l’interessato, nel momento in cui intende avvalersi del SERVIZIO.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          3. Finalità e base giuridica del trattamento
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Il Titolare effettua il trattamento dei Dati Personali per le seguenti
          finalità:
        </LBTLabel>
        <Stack
          paddingLeft="100px"
          sx={{
            listStyle: 'decimal',
            listStylePosition: 'outside',
          }}
        >
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Adempiere gli obblighi contrattuali nei confronti del soggetto che
            ha richiesto a LBT l’attivazione del SERVIZIO a favore degli UTENTI
            (es. datore di lavoro o altro soggetto contraente, di seguito il
            “CLIENTE”), nonché gestire il rapporto contrattuale col CLIENTE, ivi
            incluso ogni adempimento di natura precontrattuale e contrattuale da
            esso derivante (ai sensi dell’art. 6, par. 1, lett. b) GDPR), tra
            cui a titolo esemplificativo:
            <Stack
              paddingLeft="40px"
              sx={{
                listStyle: 'lower-roman',
                listStylePosition: 'outside',
              }}
            >
              <LBTLabel
                display="list-item"
                variant="bodyText"
                textAlign="justify"
                width="100%"
              >
                la messa a disposizione del Sito in modalità SaaS (Software as a
                Service) personalizzata;
              </LBTLabel>
              <LBTLabel
                display="list-item"
                variant="bodyText"
                textAlign="justify"
                width="100%"
              >
                la creazione/gestione/cancellazione delle credenziali di accesso
                degli UTENTI e dell’area riservata;
              </LBTLabel>
              <LBTLabel
                display="list-item"
                variant="bodyText"
                textAlign="justify"
                width="100%"
              >
                la gestione, modifica e integrazione del SERVIZIO inizialmente
                richiesto;
              </LBTLabel>
              <LBTLabel
                display="list-item"
                variant="bodyText"
                textAlign="justify"
                width="100%"
              >
                Il perseguimento di un legittimo interesse, nel rispetto degli
                interessi e dei diritti e libertà fondamentali degli
                interessati;
              </LBTLabel>
            </Stack>
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            gestire ogni genere di comunicazione il cui riscontro è disposto
            dalla normativa applicabile e vigente, a titolo esemplificativo le
            segnalazioni, l’esercizio dei diritti in materia di protezione dei
            dati personali, le recensioni negative effettuati dagli UTENTI o dal
            CLIENTE mediante qualsiasi canale ufficiale di LBT, ad esempio posta
            elettronica ordinaria/PEC (ai sensi dell’art. 6, par. 1, lett. c)
            GDPR);
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            in relazione al punto precedente, talvolta potrebbe essere
            necessario richiedere un documento di riconoscimento dell’UTENTE, al
            fine di identificare correttamente il soggetto beneficiario dei
            SERVIZI del Sito (ai sensi dell’art. 6, par. 1, lett. f) GDPR).
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Effettuare attività e analisi in ambito IT-Security e Cybersecurity,
            al fine di tutelare il patrimonio aziendale e i Dati personali degli
            UTENTI (ai sensi dell’art. 6, par. 1, lett. f) GDPR).
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Adempiere gli obblighi legali cui è sottoposta LBT, ivi inclusi
            quelli di natura fiscale, tributaria, amministrativo-contabile,
            nonché quanto necessario ad assolvere specifiche normative di
            settore.
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Qualora necessario, accertare, esercitare o difendere i diritti del
            Titolare in sede giudiziaria ed extragiudiziaria (ai sensi dell’art.
            6, par. 1, lett. f) GDPR);
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Contribuire al continuo miglioramento dei processi relativi alle
            attività svolte da LBT, ad esempio effettuando analisi statistiche,
            ivi inclusi lo sviluppo e il test di nuove funzionalità (ai sensi
            dell’art. 6, par. 1, lett. f) GDPR).
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Per quanto concerne il Sito, consentire il corretto funzionamento e
            la manutenzione dello stesso, la gestione degli accessi e delle
            credenziali, l’assistenza tecnica, nonché il miglioramento
            dell’esperienza di navigazione (ai sensi dell’art. 6, par. 1, lett.
            f) GDPR).
          </LBTLabel>
        </Stack>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Il conferimento dei Dati personali è necessario per il perseguimento
          delle finalità sopra riportate, in particolare, per l’esecuzione del
          contratto stipulato dal CLIENTE a favore degli UTENTI (terzi
          beneficiari), anche ai sensi dell’art. 1411 Cod. civ., nonché per
          adempiere gli obblighi di legge. In caso di mancato conferimento -
          parziale o totale - degli stessi, potrebbero essere pregiudicate - in
          tutto o in parte - le rispettive finalità.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          I Dati forniti dagli UTENTI, incluso quelli appartenenti a categorie
          particolari di dati ex art. 9 GDPR, saranno dati per i quali è stato
          acquisito il consenso da parte degli interessati al loro trattamento,
          ai sensi dell’art. 6 comma 1 lett. a) del GDPR, salvi i casi in cui,
          ai sensi del già menzionato art. 6, il trattamento sia consentito
          anche in assenza di consenso, in virtù di un’altra legittima base
          giuridica. Si precisa che l’accesso al SERVIZIO avviene attraverso
          un’azione proattiva degli UTENTI (inserimento username e password), di
          talché si verifica una situazione in cui tale azione implica il loro
          consenso implicito al trattamento dei Dati personali, ai sensi
          dell’Art. 7 del GDPR, inteso come raccolta, uso e condivisione dei
          Dati personali. Per quanto riguarda il trattamento dei Dati
          appartenenti a categorie particolari di dati, anche in questo caso, il
          loro trattamento richiede il previo conferimento degli stessi da parte
          degli UTENTI, attraverso la compilazione di un questionario, che
          richiede la previa manifestazione di un consenso esplicito al
          trattamento dei Dati inseriti dall’UTENTE medesimo, inclusa l’attività
          di profilazione. In mancanza di consenso, l’UTENTE non avrà accesso
          all’erogazione del SERVIZIO in modalità personalizzata, che prevede
          l’individuazione automatizzata, tramite algoritmo, dei servizi welfare
          erogabili in base al profilo dell’UTENTE, ma avrà comunque accesso al
          SERVIZIO in forma ridotta, che include solamente la navigazione sul
          Sito e l’autoricerca.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Il conferimento è facoltativo per le ulteriori finalità per cui è
          previsto il consenso espresso. L’eventuale diniego non pregiudica il
          trattamento dei Dati relativi alle finalità per cui il consenso non è
          necessario. L’esistenza del diritto di revocare il consenso non
          pregiudica la liceità del trattamento effettuato sulla base del
          consenso precedentemente prestato.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          4. Destinatari dei Dati personali
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          I Dati personali possono essere comunicati a soggetti operanti in
          qualità di autonomi titolari del trattamento, quali ad esempio
          Autorità e/o Enti pubblici nel caso in cui ciò sia previsto dalla
          legge e/o da regolamenti di settore, nonché possono essere trattati,
          sia direttamente che per conto della Società LBT, da soggetti
          ulteriori che forniscono servizi inerenti o connessi, oppure
          responsabili del trattamento, designati mediante specifico accordo ai
          sensi dell’art. 28 GDPR.
          <br />
          I Dati personali possono essere trattati dai dipendenti e/o
          collaboratori di LBT operativamente deputati al perseguimento delle
          finalità sopra indicate, che sono stati espressamente autorizzati al
          trattamento e che hanno ricevuto adeguate istruzioni operative, fermo
          l’impegno alla riservatezza.
          <br />I Dati personali potrebbero altresì essere trattati,
          limitatamente alle operazioni strettamente necessarie, da parte di
          imprese/professionisti terzi, che agiscono in qualità di responsabili
          del trattamento ai sensi dell’art. 28 GDPR, per l’esecuzione di
          attività specifiche che comportino il trattamento indiretto o
          eventuale dei Dati, quali, ad esempio, soggetti che forniscono servizi
          informatici intesi come fornitura, manutenzione e/o aggiornamento
          dell’infrastruttura informatica, del Sito web (es. provider del
          servizio di hosting) e dei software utilizzati da LBT. Il CLIENTE
          potrà avere accesso esclusivamente a dati in forma anonima e aggregata
          per finalità statistiche e di verifica dell’esecuzione del contratto
          stipulato con LBT a favore degli UTENTI beneficiari del SERVIZIO.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Resta fermo il diritto dell’interessato di richiedere in ogni momento
          un elenco aggiornato e completo dei destinatari predeterminati come
          sopra indicati.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          5. Modalità di Trattamento e “profilazione”
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          I Dati personali sono trattati mediante modalità e strumenti,
          analogici e digitali, tali da garantire la sicurezza e la riservatezza
          degli stessi, oltre al rispetto degli specifici obblighi sanciti dalla
          legge. I Dati Personali degli UTENTI sono ospitati presso
          l’infrastruttura cloud di proprietà di LBT o comunque nella sua
          giuridica disponibilità. L’infrastruttura cloud risiede su Server
          interconnessi ospitati presso un Data Center attualmente situato a
          Francoforte (Germania). La società Digital Ocean Inc. è il soggetto
          che ha la titolarità o comunque la disponibilità giuridica dei Data
          Center e il diritto di commercializzare i servizi cloud.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Il trattamento è realizzato per mezzo di una o più delle seguenti
          operazioni o complesso di operazioni: raccolta, registrazione,
          organizzazione, strutturazione, conservazione, adattamento o modifica,
          estrazione, consultazione, uso, comunicazione mediante trasmissione,
          messa a disposizione, raffronto o interconnessione, limitazione,
          cancellazione o distruzione dei dati.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          La informiamo che utilizzeremo anche sistemi automatizzati per il
          trattamento dei Suoi Dati personali, per i quali LBT ha predisposto
          adeguate misure tecniche e organizzative.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          I Suoi Dati Personali saranno soggetti a processi decisionali basati
          su trattamenti automatizzati, compresa la profilazione, e l’utilizzo
          di algoritmi, rispetto ai quali Le è stato richiesto un esplicito
          consenso (art. 22, comma 1, lett. c, e comma 4, GDPR). Attraverso la
          compilazione di un questionario, che Le richiederà l’indicazione di
          informazioni di natura personale, tra cui dati reddituali, dati
          socioeconomici, dati relativi alla composizione del nucleo familiare,
          età anagrafica, dati che implicano l’esistenza di particolari
          condizioni di salute (es. disabilità, invalidità) ecc., l’algoritmo
          utilizzato dalla Piattaforma sarà in grado di delineare il “profilo”
          dell’UTENTE e individuare, automaticamente, le misure di welfare
          pubblico e welfare bilaterale rispetto alle quali l’UTENTE dispone dei
          requisiti per presentare la relativa domanda agli enti e soggetti
          competenti a erogare tali misure.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          L’UTENTE può chiedere a LBT di verificare il profilo che è stato
          creato, quindi, non solo i Dati personali usati, ma anche quelli che
          risultano dall’analisi automatizzata dei Dati personali dallo stesso
          forniti.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          L’UTENTE può chiedere che tutto il suo profilo venga cancellato e che
          i suoi Dati vengano rettificati, e, in qualsiasi momento, può chiedere
          la limitazione della profilazione. L’UTENTE, in questo caso, è
          avvertito che l’esercizio di tali diritti potrà pregiudicare
          l’erogazione del SERVIZIO in modalità personalizzata, pur potendo lo
          stesso accedere al SERVIZIO in forma ridotta, che include solamente la
          navigazione sul Sito e l’autoricerca.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          6. Periodo di conservazione
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          I Dati personali sono conservati per il periodo di tempo strettamente
          necessario al perseguimento delle finalità sopra descritte e in
          considerazione degli obblighi legali e degli adempimenti normativi a
          cui è soggetto il Titolare del trattamento, alcuni dei quali fino a un
          periodo massimo di 10 (dieci) anni, ai sensi dell’art. 2220 Cod. civ.,
          dopodiché verranno cancellati. I Dati degli UTENTI appartenenti a
          categorie particolari di dati verranno conservati solo per il tempo
          strettamente necessario all’erogazione del SERVIZIO personalizzato
          rispetto al quale è stato prestato il relativo consenso, dopodiché
          verranno cancellati o conservati per finalità statistiche, in forma
          anonima e/o aggregata.
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Nel caso di contenzioso giudiziale, i Dati personali verranno
          conservati per tutta la durata dello stesso, fino all’esaurimento dei
          termini di esperibilità delle azioni di impugnazione. Con riguardo
          alle finalità che prevedono uno specifico consenso esplicito, il
          trattamento viene effettuato fino alla revoca dello stesso.
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          7. Trasferimento verso Paesi terzi
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          I Dati personali raccolti da LBT non sono oggetto di trasferimento
          verso Paesi non appartenenti al territorio dell’Unione Europea e dello
          Spazio Economico Europeo (“<BoldSpan>SEE</BoldSpan>”) oppure verso
          organizzazioni internazionali. Qualora sia necessario comunicare tali
          Dati a destinatari situati fuori dallo SEE, il Titolare assicura che
          il trattamento avverrà in conformità alla normativa vigente
          applicabile, ad esempio in virtù di una decisione di adeguatezza della
          Commissione Europea (ai sensi dell’art. 45 GDPR) oppure delle Clausole
          Contrattuali Standard approvate dalla Commissione europea (ai sensi
          dell’art. 46 GDPR), considerando la giurisprudenza della Corte di
          Giustizia dell’Unione Europea (“<BoldSpan>CGUE</BoldSpan>”) e le
          decisioni n. 1/20 e 2/20 emesse dal Comitato Europeo per la Protezione
          dei Dati (“<BoldSpan>EDPB</BoldSpan>”).
        </LBTLabel>
        <LBTLabel
          variant="spGroteskSubtitle3"
          width="100%"
          textAlign="left"
          component="h3"
          paddingLeft="40px"
          boxSizing="border-box"
        >
          8. Diritti dell'interessato
        </LBTLabel>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          Laddove applicabile, in base a quanto previsto dagli artt. 15 e
          successivi del GDPR, l’interessato può contattare il Titolare del
          trattamento all’indirizzo e-mail{' '}
          <Link
            href="mailto:privacy@laborability.com"
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          >
            privacy@laborability.com
          </Link>{' '}
          e/o il DPO all’indirizzo e-mail{' '}
          <Link
            href="mailto:dpo@laborability.com"
            color={COLORS.getInstance().BW_GREYS_JET_BLACK}
          >
            dpo@laborability.com
          </Link>{' '}
          in qualsiasi momento, al fine di:
        </LBTLabel>
        <Stack
          paddingLeft="100px"
          sx={{
            listStyle: 'circle',
            listStylePosition: 'outside',
          }}
        >
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            chiedere l’accesso ai Dati personali che lo riguardano, la loro
            cancellazione, la rettifica dei Dati personali errati,
            l’integrazione dei Dati incompleti, nonché la limitazione del
            trattamento.
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Ricevere, ove previsto, in un formato strutturato, di uso comune e
            leggibile da dispositivo automatico i Dati personali, nonché, se
            tecnicamente fattibile, di trasmetterli a un altro titolare senza
            impedimenti (“diritto alla portabilità dei dati”).
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Opporsi al trattamento nelle ipotesi di legittimo interesse del
            Titolare per motivi connessi alla situazione particolare.
          </LBTLabel>
          <LBTLabel
            display="list-item"
            variant="bodyText"
            textAlign="justify"
            width="calc(100% - 50px)"
          >
            Revocare in qualsiasi momento il consenso facoltativo e/o
            obbligatorio e liberamente prestato.
          </LBTLabel>
        </Stack>
        <LBTLabel variant="bodyText" textAlign="justify" width="100%">
          L’interessato ha altresì il diritto di proporre reclamo all'Autorità
          di controllo competente.
        </LBTLabel>
      </Stack>
    </Section>
  );
}
