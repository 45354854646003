import { useBreakpoint } from '@laborability/commons';
import { LBTLabel, LBTSpacer, Section } from '@laborability/components';
import BoldSpan from '@laborability/components/src/components/Questionary/BoldSpan';
import styled from 'styled-components';

export default function CookiePolicy() {
  const { isDesktop } = useBreakpoint();

  const Table = styled('table')(() => ({
    borderCollapse: 'collapse',
    width: '100%',
    maxWidth: '680px',
    '& th': {
      border: '1px solid #000',
      padding: '4px',
      textAlign: 'left',
    },
    '& td': {
      border: '1px solid #000',
      padding: '4px',
    },
  }));

  const List = styled('ul')(() => ({}));

  interface Cookie {
    name: string;
    provider: string;
    scope: string;
    duration: string;
  }

  interface Browser {
    name: string;
    link: string;
  }

  const cookies: Array<Cookie> = [
    {
      name: '__cf_bm',
      provider: 'auth.laborability.com',
      scope:
        "Questo cookie è usato per distinguere tra umani e robot. Questo è utile per il sito web, al fine di rendere validi rapporti sull'uso del sito",
      duration: '1 giorno',
    },
    {
      name: 'AUTH_SESSION_ID',
      provider: 'auth.laborability.com',
      scope:
        'Questo cookie è necessario per la funzione di accesso al sito web.',
      duration: 'Durata della sessione',
    },
    {
      name: 'AUTH_SESSION_ID_LEGACY',
      provider: 'auth.laborability.com',
      scope:
        'Questo cookie salva le credenziali del visitatore in un cookie criptato per consentire al visitatore di rimanere autenticato in occasione di visite successive, se richiesto con il pulsante “rimani autenticato”.',
      duration: 'Durata della sessione',
    },
    {
      name: 'KC_RESTART',
      provider: 'auth.laborability.com',
      scope:
        "Identifica l'utente connesso. Un ID unico di sessione è collegata all'utente così che sia identificato mentre naviga sul sito internet. L'utente viene disconnesso alla scadenza del cookie.",
      duration: 'Durata della sessione',
    },
    {
      name: '_cf_bm',
      provider: 'laborability.com',
      scope:
        'Utilizzato da Cloudflare per distinguere il traffico umano da quello bot e proteggere il sito da attività dannose.',
      duration: '1 giorno',
    },
    {
      name: '_clck',
      provider: 'laborability.com',
      scope:
        "Utilizzato da Microsoft Clarity per identificare in modo univoco e anonimo il browser e tracciare l'attività dell'utente nel corso di più visite",
      duration: '1 anno',
    },
    {
      name: '_clsk',
      provider: 'laborability.com',
      scope:
        "Utilizzato da Microsoft Clarity per identificare in modo univoco e anonimo il browser e tracciare l'attività dell'utente nel corso di più visite",
      duration: '1 anno',
    },
    {
      name: '_ga',
      provider: 'laborability.com',
      scope:
        'Utilizzato per distinguere gli utenti tramite Google Analytics in forma anonima, per raccogliere dati statistici sul comportamento dei visitatori',
      duration: '2 anni',
    },
    {
      name: '_ga_xxxxxxxxxx',
      provider: 'laborability.com',
      scope:
        'Utilizzato per distinguere gli utenti tramite Google Analytics in forma anonima, per raccogliere dati statistici sul comportamento dei visitatori',
      duration: '2 anni',
    },
    {
      name: 'cookie_policy',
      provider: 'laborability.com',
      scope:
        "Registra l'avvenuta accettazione del banner informativo sui cookie.",
      duration: '180 giorni',
    },
  ];

  const browser: Array<Browser> = [
    {
      name: 'Google Chrome',
      link: 'https://support.google.com/chrome/answer/95647?hl=it&co=GENIE.Platform%3DDesktop',
    },
    {
      name: 'Mozilla Firefox',
      link: 'https://support.mozilla.org/it/kb/Eliminare%20i%20cookie',
    },
    {
      name: 'Microsoft Edge',
      link: 'https://support.microsoft.com/it-it/windows/gestire-i-cookie-in-microsoft-edge-visualizzare-consentire-bloccare-eliminare-e-usare-168dab11-0753-043d-7c16-ede5947fc64d',
    },
    { name: 'Safari', link: 'https://support.apple.com/it-it/105082' },
  ];

  return (
    <Section alignItems="start" margin="auto">
      <LBTSpacer spacing={2} />
      <LBTLabel variant="delaDisplay" component="h1">
        Cookie policy
      </LBTLabel>
      <LBTSpacer spacing={4} />
      <LBTLabel textAlign="left" variant="bodyText" component="p">
        Dritto, la piattaforma di laborability dedicata al welfare pubblico,
        utilizza sul proprio sito cookie tecnici, ivi assimilati anche
        tracciamenti a scopo analitico (cfr.{' '}
        <a
          target="_blank"
          href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/3118884"
          rel="noreferrer"
        >
          provvedimento dell'8 maggio 2014
        </a>{' '}
        e{' '}
        <a
          target="_blank"
          href="https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/9677876"
          rel="noreferrer"
        >
          Linee guida cookie e altri strumenti di tracciamento del 10 giugno
          2021
        </a>
        , del{' '}
        <a
          target="_blank"
          href="https://www.garanteprivacy.it/home/autorita"
          rel="noreferrer"
        >
          Garante per la protezione dei dati personali
        </a>
        ), di prime e terze parti, al fine di migliorare costantemente il
        prodotto e offrirti la migliore esperienza di utilizzo. I cookie ci
        consentono, tra le altre cose, di ottenere dati statistici in forma
        anonima e aggregata in merito alle visite e all'utilizzo del sito.
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel textAlign="left" variant="bodyText" component="p">
        I cookie sono piccoli file di testo che possono essere utilizzati dai
        siti web per rendere più efficiente l'esperienza per l'utente. La legge
        afferma che possiamo memorizzare i cookie sul tuo dispositivo se
        necessari per il funzionamento di questo sito o per migliorare
        l'esperienza di utilizzo dello stesso.
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel textAlign="left" variant="bodyText" component="p">
        Dichiarazione cookie aggiornata in data <BoldSpan>28/11/2024</BoldSpan>:
      </LBTLabel>
      <LBTSpacer spacing={2} />
      {isDesktop ? (
        <Table>
          <tr>
            <th>
              <LBTLabel textAlign="left" variant="bodyText" component="p">
                <BoldSpan>Nome</BoldSpan>
              </LBTLabel>
            </th>
            <th>
              <LBTLabel textAlign="left" variant="bodyText" component="p">
                <BoldSpan>Provider</BoldSpan>
              </LBTLabel>
            </th>
            <th>
              <LBTLabel textAlign="left" variant="bodyText" component="p">
                <BoldSpan>Scopo</BoldSpan>
              </LBTLabel>
            </th>
            <th>
              <LBTLabel textAlign="left" variant="bodyText" component="p">
                <BoldSpan>Durata massima di archiviazione</BoldSpan>
              </LBTLabel>
            </th>
          </tr>
          {cookies.map(cookie => (
            <tr>
              <td>
                <LBTLabel textAlign="left" variant="bodyText" component="p">
                  <BoldSpan>{cookie.name}</BoldSpan>
                </LBTLabel>
              </td>
              <td>
                <LBTLabel textAlign="left" variant="bodyText" component="p">
                  {cookie.provider}
                </LBTLabel>
              </td>
              <td>
                <LBTLabel textAlign="left" variant="bodyText" component="p">
                  {cookie.scope}
                </LBTLabel>
              </td>
              <td>
                <LBTLabel textAlign="left" variant="bodyText" component="p">
                  {cookie.duration}
                </LBTLabel>
              </td>
            </tr>
          ))}
        </Table>
      ) : (
        <Table>
          <tr>
            <th>
              <LBTLabel textAlign="left" variant="bodyText" component="p">
                <BoldSpan>Nome</BoldSpan>
              </LBTLabel>
            </th>
            <th>
              <LBTLabel textAlign="left" variant="bodyText" component="p">
                <BoldSpan>Provider</BoldSpan>
              </LBTLabel>
            </th>
          </tr>
          {cookies.map(cookie => (
            <>
              <tr>
                <td>
                  <LBTLabel textAlign="left" variant="bodyText" component="p">
                    <BoldSpan>{cookie.name}</BoldSpan>
                  </LBTLabel>
                </td>

                <td>
                  <LBTLabel textAlign="left" variant="bodyText" component="p">
                    {cookie.provider}
                  </LBTLabel>
                </td>
              </tr>
              <tr>
                <td colSpan={3}>
                  <LBTLabel textAlign="left" variant="bodyText" component="p">
                    {cookie.scope}
                  </LBTLabel>
                </td>
              </tr>
              <tr>
                <th>
                  <LBTLabel textAlign="left" variant="bodyText" component="p">
                    <BoldSpan>Durata massima di archiviazione</BoldSpan>
                  </LBTLabel>
                </th>
                <td>
                  <LBTLabel textAlign="left" variant="bodyText" component="p">
                    {cookie.duration}
                  </LBTLabel>
                </td>
              </tr>
              <LBTSpacer spacing={2} />
            </>
          ))}
        </Table>
      )}
      <LBTSpacer spacing={2} />
      <LBTLabel textAlign="left" variant="bodyText" component="p">
        <BoldSpan>Come cancellare i cookie nei principali browser</BoldSpan>
      </LBTLabel>
      <LBTSpacer spacing={2} />
      <LBTLabel textAlign="left" variant="bodyText" component="p">
        Se desideri gestire o eliminare i cookie salvati sul tuo dispositivo,
        puoi farlo attraverso le impostazioni del tuo browser. Di seguito trovi
        i link alle guide ufficiali dei browser più utilizzati:
      </LBTLabel>
      <List>
        {browser.map(browser => (
          <li>
            <LBTLabel textAlign="left" variant="bodyText" component="p">
              <a target="_blank" href={browser.link} rel="noreferrer">
                {browser.name}
              </a>
            </LBTLabel>
          </li>
        ))}
      </List>
      <LBTSpacer spacing={8} isFixed />
    </Section>
  );
}
