import {
  STATIC_QUESTIONARY_NUMBER,
  currentUserState,
  getAllQuestionFlowCallback,
  getUserProfileCallback,
  questionFlowsLoaderState,
  questionFlowsState,
  userLoaderState,
} from '@laborability/commons';
import { useContext, useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import Intro from './Intro';
import MyConcessions from './MyConcessions';
import { Flow, QuestionaryContext } from '@laborability/components';
import { useLocation, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../../Routes';
import QuestionaryLoader from './QuestionaryLoader';

export default function QuestionaryIndex() {
  const { state } = useLocation();
  const getFlows = useRecoilCallback(getAllQuestionFlowCallback, []);
  const getUserMe = useRecoilCallback(getUserProfileCallback, []);
  const flows = useRecoilValue(questionFlowsState);
  const flowsLoader = useRecoilValue(questionFlowsLoaderState);
  const userLoader = useRecoilValue(userLoaderState);
  const currentUser = useRecoilValue(currentUserState);
  const [showIntro, setShowIntro] = useState(true);
  const {
    setStep,
    step,
    setPageId,
    pageId,
    isStepCompleted,
    setIsStepCompleted,
  } = useContext(QuestionaryContext);
  const navigate = useNavigate();

  useEffect(() => {
    getFlows();
    if (!step) getUserMe();
  }, [step]);

  useEffect(() => {
    if (isStepCompleted) {
      setStep(0);
      setPageId(0);
    }
    // valori di default passati dall'esterno
    else if (state?.step !== undefined && state?.pageId !== undefined) {
      setStep(state.step);
      setPageId(state.pageId);
    }
    // nessun dato, inizio dal primo questionario
    else if (!currentUser.questionary_status?.length) {
      setStep(1);
      setPageId(0);
    }
    // i primi 3 questionari sono obbligatori per procedere ai successivi
    else if (
      currentUser.questionary_status?.length <= STATIC_QUESTIONARY_NUMBER
    ) {
      const ongoingStep = currentUser.questionary_status?.find(
        item => !item.completed,
      );
      if (ongoingStep) {
        setStep(ongoingStep?.step ? ongoingStep.step : 0);
        setPageId(ongoingStep?.page_id ? ongoingStep.page_id : 0);
      } else {
        const completedSteps = currentUser.questionary_status
          .filter(item => item.completed)
          .map(item => item.step);
        const allSteps = Array.from(
          Array(STATIC_QUESTIONARY_NUMBER).keys(),
        ).map(i => i + 1);
        const nextStep = allSteps.find(step => !completedSteps.includes(step));
        setStep(nextStep ?? 0);
        setPageId(0);
      }
    }
    // mostra menù dei questionari seccessivi a quelli obbligatori
    else {
      setStep(0);
      setPageId(0);
    }
  }, [currentUser]);

  useEffect(() => {
    navigate(`/${APP_ROUTES.QUESTIONARY}`, {
      state: { step: step, pageId: pageId },
    });
  }, [step, pageId]);

  if (flowsLoader || userLoader) return null;

  if (!currentUser.questionary_status?.length && showIntro)
    return <Intro closeIntro={() => setShowIntro(false)} />;

  const flow = flows.find(item => item.step === step);

  if (isStepCompleted)
    return (
      <QuestionaryLoader
        step={step}
        setStep={setStep}
        setIsStepCompleted={setIsStepCompleted}
      />
    );

  if (step && flow) return <Flow flow={flow} />;

  return <MyConcessions setStep={setStep} />;
}
